<template>
  <v-app :class="currentPage">
    <nav-bar />
    <v-navigation-drawer
      app
      mini-variant
      rail
      permanent
      clipped
      color="grey lighten-3"
      class="pt-15 navigation-sidebar"
    >
      <v-list>
        <router-link
          v-for="item in drawerItems"
          :key="item.title"
          :id="item.title"
          :to="`${item.url}`"
          v-bind:item="item"
        >
          <v-hover
            v-slot:default="{ hover }"
          >
            <v-list-item
              @click="setNextPage(item)"
              :class="{
                'active-icon': isPathActive(item.title),
                'custom-hover': hover
              }"
              style="border-bottom: none !important;"
            >

              <v-tooltip
                right
                class="tooltip-style"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    plain
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div :class="{'rounded-border': isPathActive(item.title)}">
                      <v-icon color="drawer-icon">
                        {{ item.icon }}
                      </v-icon>
                    </div>
                  </v-btn>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>

              <v-icon class="drawer-icon"></v-icon>
              <v-tooltip class="tooltip-style" right></v-tooltip>
              <span class="md-list-item-text drawer-icon-text">{{ item.title }}</span>
            </v-list-item>
          </v-hover>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view > </router-view>
      <NoAccessDialog></NoAccessDialog>
    </v-main>
    <footer>
      <div class="stratas-footer-content">
        <div class="stratas-footer-logo-container">
            <svg class="mt-1 px-1 pl-3" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 339.19 86">
              <defs>
              </defs>
              <g id="Layer_1-2" data-name="Layer 1">
                <g>
                  <!-- eslint-disable max-len -->
                  <path class="cls-3" d="M95.9,38.63V0h9.75v38.63h-9.75ZM103.63,22.99v-6.97h7.36c1.6,0,2.82-.39,3.68-1.17.85-.78,1.28-1.84,1.28-3.19s-.43-2.37-1.28-3.19c-.85-.82-2.08-1.23-3.68-1.23h-7.36V0h8.72c2.62,0,4.91.49,6.89,1.47,1.98.98,3.53,2.33,4.66,4.03,1.13,1.71,1.69,3.7,1.69,5.99s-.57,4.3-1.72,6.02-2.73,3.07-4.77,4.03c-2.03.96-4.41,1.44-7.14,1.44h-8.34ZM116.82,38.63l-11.22-16.56,8.77-2.13,13.73,18.69h-11.28Z"/>
                  <path class="cls-3" d="M128.27,38.63L143.25,0h9.43l14.82,38.63h-10.19l-11.17-32.47h3.54l-11.44,32.47h-9.97ZM137.36,31.87v-7.74h21.3v7.74h-21.3Z"/>
                  <path class="cls-3" d="M169.24,38.63V0h6.81l2.94,7.9v30.73h-9.75ZM195.89,38.63l-22.56-28.66,2.72-9.97,22.61,28.66-2.78,9.97ZM195.89,38.63l-2.51-8.12V0h9.75v38.63h-7.25Z"/>
                  <path class="cls-3" d="M207.82,38.63V0h9.75v38.63h-9.75ZM214.41,38.63v-8.39h9.26c2.18,0,4.08-.42,5.69-1.25,1.62-.83,2.86-2.08,3.73-3.73.87-1.65,1.31-3.66,1.31-6.02s-.45-4.29-1.34-5.91c-.89-1.62-2.13-2.84-3.73-3.68s-3.49-1.25-5.67-1.25h-9.7V0h9.75c2.98,0,5.72.46,8.23,1.39s4.69,2.24,6.54,3.95c1.85,1.71,3.3,3.74,4.33,6.1,1.03,2.36,1.55,4.98,1.55,7.85s-.52,5.54-1.55,7.9c-1.03,2.36-2.48,4.4-4.33,6.1-1.85,1.71-4.02,3.02-6.51,3.95s-5.2,1.39-8.15,1.39h-9.42Z"/>
                  <path class="cls-3" d="M241.68,38.63L256.67,0h9.43l14.82,38.63h-10.19l-11.17-32.47h3.54l-11.44,32.47h-9.97ZM250.78,31.87v-7.74h21.3v7.74h-21.3Z"/>
                  <path class="cls-3" d="M283.39,38.63V0h9.75v38.63h-9.75ZM291.13,38.63v-8.39h18.63v8.39h-18.63Z"/>
                  <path class="cls-3" d="M312.82,38.63V0h9.75v38.63h-9.75ZM320.55,38.63v-8.39h18.63v8.39h-18.63Z"/>
                  <path class="cls-3" d="M95.9,86v-38.63h9.75v38.63h-9.75ZM103.63,70.36v-6.97h7.36c1.6,0,2.82-.39,3.68-1.17.85-.78,1.28-1.84,1.28-3.19s-.43-2.37-1.28-3.19c-.85-.82-2.08-1.23-3.68-1.23h-7.36v-7.25h8.72c2.62,0,4.91.49,6.89,1.47,1.98.98,3.53,2.33,4.66,4.03,1.13,1.71,1.69,3.7,1.69,5.99s-.57,4.3-1.72,6.02-2.73,3.07-4.77,4.03c-2.03.96-4.41,1.44-7.14,1.44h-8.34ZM116.82,86l-11.22-16.56,8.77-2.13,13.73,18.69h-11.28Z"/>
                  <path class="cls-3" d="M130.14,86v-38.63h9.75v38.63h-9.75ZM137.88,55.59v-8.23h20.6v8.23h-20.6ZM137.88,70.31v-8.01h18.96v8.01h-18.96ZM137.88,86v-8.23h20.92v8.23h-20.92Z"/>
                  <path class="cls-3" d="M163.21,86v-38.63h9.75v38.63h-9.75Z"/>
                  <path class="cls-3" d="M177.65,86v-38.63h9.75v38.63h-9.75ZM185.39,86v-8.39h18.63v8.39h-18.63Z"/>
                  <path class="cls-3" d="M208.54,86v-38.63h9.75v38.63h-9.75ZM216.28,86v-8.39h18.63v8.39h-18.63Z"/>
                  <path class="cls-3" d="M243.3,72.16l-14.77-24.79h11.17l11.28,20.1h-7.85l11.28-20.1h10.95l-14.87,24.79h-7.19ZM242.16,86v-17.82h9.75v17.82h-9.75Z"/>
                </g>
                <g>
                  <path class="cls-1" d="M23.31,65.33H0V20.67h23.31v44.66ZM53.35,65.33h23.31s0-44.66,0-44.66h-23.31s0,44.66,0,44.66Z"/>
                  <path class="cls-2" d="M0,20.67L23.31.03h30.05v20.64H0ZM23.31,65.33v20.64s30.05,0,30.05,0l23.31-20.64H23.31Z"/>
                  <!-- eslint-disable max-len -->
                </g>
              </g>
            </svg>
        </div>

        <div class="stratas-footer-text">
          <div class="stratas-footer-text-left">
            <span >&copy; {{ determineDate() }} Randall Reilly. All rights reserved.</span>
          </div>
          <div class="stratas-footer-text-right">
            <span>
              <a href="https://randallreilly.com/legal/website-terms-and-conditions/" style="color: white;" target="_blank">Terms of Use</a>
              <span class="stratas-footer-text-pipe">|</span>
              <a href="https://randallreilly.com/legal/privacy-policy" style="color: white;" target="_blank">Privacy Policy</a>
              <span class="stratas-footer-text-pipe">|</span>
              <a href="https://randallreilly.com/legal/dsar" style="color: white;" target="_blank">
                Do Not Sell or Share My Personal Information
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import identifyVisitor from '../../helpers/html-support-setup';
import NavBar from '../Navbar/NavBar.vue';
import NoAccessDialog from '../Dialogs/NoAccessDialog.vue';

export default {
  name: 'MainWrapper',
  components: {
    NavBar,
    NoAccessDialog,
  },
  props: {
    drawerItems: { type: Array, required: true },
  },
  data: () => ({
    menuVisible: false,
    height: '',
  }),
  mounted() {
    // eslint-disable-next-line max-len
    identifyVisitor({ userEmail: this.userEmail, userRoles: this.userRoles, userSsoId: this.userSsoId });
    if (this.$chameleon && this.userEmail) {
      this.$chameleon.identify(this.userSsoId, {
        uid_hash: this.chameleonId,
        email: this.userEmail,
        roles: this.userRoles,
        project: 'Strategize',
      });
    }
  },
  computed: {
    currentPage() {
      return this.$route.name.toLowerCase();
    },
    ...mapGetters({
      userRoles: 'auth/getUserRoles',
      userEmail: 'auth/getUserEmail',
      userSsoId: 'auth/getUserSsoId',
      chameleonId: 'auth/getChameleonId',
    }),
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    determineDate() {
      return new Date().getFullYear();
    },
    isPathActive(item) {
      return this.$route.name === item;
    },
    setNextPage(page) {
      let pageValue = {
        linkId: page.title,
      };
      pageValue = JSON.stringify(pageValue);
      sessionStorage.setItem('lastPageViewed', pageValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #5e883c;
}

.cls-1, .cls-2, .cls-3 {
  stroke-width: 0px;
}

.cls-2 {
  fill: #76aa41;
}

.cls-3 {
  fill: #f5f3f1;
}
.maps .v-main {
  position: fixed;
  min-width: 100%;
}
.maps footer {
  bottom: 0;
  position: absolute;
}
.drawer-icon {
  color: #878787 !important;
}
.drawer-icon-text {
  color: #878787 !important;
  font-weight: 700;
}
.tooltip-style {
  background-color: #000000;
  color: #efefef;
  font-size: 0.8rem;
  margin-left: 10% !important;
}
.stratas-footer-content {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: #444444;
  height: 46px;
  width: 100vw;
  color: #f5f5f5;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 12px;
  z-index: 10;
}

.stratas-footer-content{
  display: flex;
  align-items: center;
  text-align: center;
}

.stratas-footer-logo-container {
  margin: 6px 0 6px 11px;
  width: 130px;
  display: inline;
}

.stratas-footer-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stratas-footer-text-left {
  margin-left: 16px;
}

.stratas-footer-text-right {
  position: relative;
  right: 36px;
}

.stratas-footer-text-right a {
  text-decoration: none;
  color: inherit;
}

.stratas-footer-text-pipe {
  margin: 0 4px;
}

.navigation-sidebar {
  .v-list-item {
    padding: 0 !important;
  }

  .custom-hover {
    background-color: #F7F8FA !important;

    .v-icon {
      color: #538703 !important;
    }
  }

  .v-list-item--link:before {
    background-color: transparent !important;
  }

  .active-icon {
    background-color: transparent !important;

    .v-icon {
      color: #538703 !important;
    }
  }

  rounded-border {
    position: relative;
    overflow: hidden;
    background-color: transparent;
  }

  .rounded-border::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #538703;
    border-radius: 10%;
    margin: auto 0;
  }

  .v-btn--icon.v-size--default {
    color: #4A4A4A !important;

    .v-icon {
      height: 48px !important;
      font-size: 32px !important;
      margin-left: 35% !important;
    }
  }
}
.locations, .trends, .driver, .mechanic {
  .v-tooltip__content--fixed {
    margin-left: 1% !important;
  }
}
</style>
